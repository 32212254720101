<template>
  <v-container>
    <v-row>
      <v-col col-12 col-md-6>
        <v-autocomplete
          v-model="modelOrigen"
          :items="itemsOrigen"  
          color="white"
          hide-no-data
          hide-selected
          item-text="label"
          item-value="id"
          label="Origen de Envio"
          placeholder="Asistente"
          prepend-icon="mdi-robot"
          return-object
          @change="seleccionAsistente"
        ></v-autocomplete>
        <v-autocomplete
          v-model="model"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          color="white"
          hide-no-data
          hide-selected
          item-text="nombre_hsm"
          item-value="nombre_hsm"
          label="HSMs disponibles"
          placeholder="Buscar HSM"
          prepend-icon="mdi-database-search"
          return-object
        ></v-autocomplete>
        <v-btn v-show="model" @click="exportar" color="info" >
          <v-icon left>
            mdi-file-download
          </v-icon>
          Plantilla ejemplo
        </v-btn>
        <v-file-input
          v-model="files"
          truncate-length="1"
          :label="model==null?'Selecciona el HSM':'Adjuntar excel con parametros: '+model.params"
          show-size
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          prepend-icon="mdi-file-excel"
          :disabled="model==null"
          @change="verArchivo"
        ></v-file-input>

        <v-text-field
            v-model="telefononotificacion" 
            counter="10"
            label="Numero a notificar inicio y fin de envio. (Opcional)"
            prepend-icon="mdi-bell"
            type="number" 
          ></v-text-field>
        <!-- <v-btn v-show="files" @click="enviarArchivo" class="float-right" color="success" :loading="isLoading">
          Enviar ahora
          <v-icon right>
            mdi-send
          </v-icon>
        </v-btn> -->

        <v-alert
          prominent
          type="error"
          v-if="error"
        >
          <v-row align="center">
            <v-col class="grow">
              No se mandó a: <br>
              <ul v-for="(parametro, index) in respuesta.noenviados" :key="index"><li>{{parametro.telefono}}</li></ul>
            </v-col>
            <v-col class="shrink">
              <v-btn @click="error=false">OK</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col col-12 col-md-6>
        <div class="col-12 col-md-6 d-flex justify-content-center">
          <div class="bgWA d-none d-md-block">
            <img class="bannerWA" src="../../assets/imgs/bannerWA.png" alt="Banner">
            <div v-if="model" class="mensajeWA">
              <p class="textWA">{{ model.mensaje }}</p>
              <!-- <small class="horaWA"
                >{{ hoy.toString().substr(16, 5) }} hrs</small
              > -->
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <alertas-vue :text="'Se agregó el envio a la cola'" :alerta="enviado" :color="'success'" :timeout="5000"></alertas-vue>
  </v-container>
</template>
<script>
import Servicio from '@/service/apis'
import { readExcel } from "@/plugins/importExcel";
import AlertasVue from '../Alertas.vue';
import * as XLSX from 'xlsx';
export default {
  name:'HSM',
  data: () => ({
    model:null,
    isLoading:false,
    files:null,
    modelOrigen:{id:1,label:'Whatsapp Mexico'},
    items:null,
    itemsOrigen :[{id:1,label:'Whatsapp Mexico'},{id:2,label:'Whatsapp Hemodialisis'},{id:3,label:'Whatsapp San Salvador'} ,{id:4,label:'Whatsapp PetCt'} ,{id:5,label:'Whatsapp Medicos'},{id:6,label:'Whatsapp Medicos Internacional'} ],
    excel:null,
    hoy: new Date(),
    search:null,
    sesion:JSON.parse(sessionStorage.getItem('sesion')),
    error:false,
    respuesta:null,
    enviado:false,
    telefononotificacion:""
  }),
  components:{
    AlertasVue
  },
  props:{
    error:Boolean,
    respuesta:Object,
  },
  created(){
    Servicio.getAllHsm(this.modelOrigen.id).then((resp)=>{
          this.items=resp.data
    })
  },
  updated(){
    this.evento()
  },
  methods:{
    cargado(){
      this.excel = readExcel(this.files);
    },
    evento(){ 
      this.$emit('archivo', this.files)
      this.model.origen = this.modelOrigen
      this.model.telefononotificacion = this.telefononotificacion
      if(this.model){
        this.$emit('modelo', this.model) 
      }
    },
    enviarArchivo(){
      this.isLoading=true
      let formData = new FormData()
      formData.append("files", this.files)
      formData.append("capturista_id",this.sesion.id)
      formData.append("hsm_id", this.model.hsmid)
      formData.append("tipo_envio", 1)
      formData.append("notificacion_telefono", this.telefononotificacion) 
      Servicio.subirArchivo(formData).then((resp)=>{
        this.respuesta=resp
        if(this.respuesta.noenviados[0]){
          this.error=true
        }else{
          this.error=false
          this.enviado=true
        }
      }).finally(()=>{
        this.isLoading=false
      })
    },
    exportar() {
      let parametros=[]
      for(const x of this.model.params) {
        parametros[x]=''
      }
      let data = XLSX.utils.json_to_sheet([parametros])
      const workbook = XLSX.utils.book_new()
      const filename = this.model.nombre_hsm.substring(0,30)
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    seleccionAsistente(event) {
      console.log(event)
        // this.$toast.add({severity: 'success',  
        this.model = ''
        Servicio.getAllHsm(this.modelOrigen.id).then((resp)=>{
          this.items=resp.data
        })
    },
    verArchivo(){
      console.log('nombre archivo:')
      console.log(this.files.name)
      // this.evento()
    }
  }
}
</script>