<template>
  <div>
    <v-stepper
      v-model="nivel"
      vertical
      
    >
      <v-stepper-step
        :complete="nivel > 1"
        step="1"
        color="success"
      >
        Elegir Plantilla
        <small>Elige entre el catalogo actual de HSMs para hacer el envío</small>   
                  
      </v-stepper-step>
              
      <v-stepper-content step="1">
      <hsm @archivo="archivoDeExcel" @modelo="nombreDeHsm" :error="error" :respuesta="respuesta" />
        

        <!-- <v-btn text>
          Cancelar
        </v-btn> -->
        <v-btn
          color="success"
          @click="nivel = 2"
          :disabled="!excel"
        >
          Continuar
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        :complete="nivel > 2"
        step="2"
        color="success"
      >
        Configuración del envio
        <small></small>
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-container>
          <v-row>
            <v-switch
                v-model="tipoEnvio"
                label="Envio inmediato"
                color="orange darken-3"
                hide-details
              ></v-switch>
            
          </v-row>
          <v-row>
            <v-switch
                v-model="repetidos"
                label="Permitir envio a numeros repetidos"
                color="orange darken-3"
                hide-details
              ></v-switch>
            
          </v-row>
          <v-row>
            <v-switch
                v-model="pruebas"
                label="¿Permitir que el paciente ya haya recibió hsms anteriormente?"
                color="orange darken-3"
                hide-details
              ></v-switch> 
          </v-row>
          <v-row class="col-4"> 
            <v-card-text class="pt-0">
              <v-slider 
                v-model="diasHsm"
                :rules="rules"
                label="Dias"
                step="1"
                max="7"
                min="0"
                thumb-label 
                ticks
              ></v-slider>
            </v-card-text>
          </v-row>
          <v-row transition="slide-y-transition" v-show="!tipoEnvio">
            <v-divider></v-divider>
            <v-col cols="12">
              <v-text-field
                label="Motivo del envio"
                v-model="envio.descripcion"
                placeholder="Breve descripcion del envío"
                filled
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
            >
              <v-menu
                v-model="menu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha"
                    label="Fecha"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fecha"
                  no-title
                  :min="dateplaceholder"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-select
                label="Hora"
                filled
                prepend-icon="mdi-clock"
                :items="horas"
                v-model="hora"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                label="Minuto"
                filled
                prepend-icon=":"
                :items="minutos"
                v-model="minuto"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-btn text @click="nivel = 1">
          Regresar
        </v-btn>
        <v-btn
          color="success"
          @click="nivel = 3"
          :disabled="continuar2()"
        >
          Continuar
        </v-btn>
      </v-stepper-content>

      <v-stepper-step
        :complete="nivel > 3"
        step="3"
        color="success"
      >
        Resumen
        <small>Corrobora que la información sea la correcta...</small>
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-container>
          <v-row>
            <v-col>
              <v-banner two-line>
                <v-avatar
                  slot="icon"
                  color="success"
                  size="40"
                >
                  <v-icon
                    icon="mdi-lock"
                    color="white"
                  >
                    mdi-send
                  </v-icon>
                </v-avatar>
                <v-row> 
                </v-row>
                Está a punto de mandar la plantilla: <b>{{model.nombre_hsm}}</b>  {{tipoEnvio?' a envio inmediato a la cola de envios. ':('para el día: '+fecha+' a las '+hora+':'+minuto+'hrs')}} <br>
                {{envio.descripcion?('con motivo de '+envio.descripcion):'Al enviarlo está conciente que podría no llegar inmediatamente, será enviado a cola y dependiendo de los envios encolados se estaría enviando posteriormente.'}}
                
                <template v-slot:actions class="ml-0">
                  <v-btn
                    text
                    color="red darken-3"
                    @click="nivel=2"
                  >
                    Cambiar fecha/hora
                  </v-btn>
                  <v-btn
                    text
                    color="success"
                    @click="tipoEnvio?enviarArchivo(0):generarEnvio()"
                    :loading="isLoading"
                  >
                    Mandar a cola de envío
                  </v-btn>
                </template>
              </v-banner>
            </v-col>
          </v-row>
        </v-container>
        <!-- <v-btn text @click="nivel=2" >
          Cambiar fecha/hora
        </v-btn> -->
      </v-stepper-content>
    </v-stepper>
    <alertas-vue :text="'Se agregó el envio a la cola'" :alerta="enviado" :color="'success'" :timeout="5000"></alertas-vue>
  </div>
</template>
<script>
import {_} from 'vue-underscore'
import Servicio from '@/service/apis'
import hsm from '../flujo/hsm'
import AlertasVue from '../Alertas.vue' 

export default {
  name:'Flujo',
  data: () => ({ 
    cola_actual :0,
    show: false,
    nivel: 1,
    pruebas : 0,
    dias:true,
    diasHsm:1,
    hsms: [],
    sesion:JSON.parse(sessionStorage.getItem('sesion')),
    excel:{},
    model:{nombre_hsm:null},
    modelOrigen:{origen:null},
    tipoEnvio:true,
    repetidos:false,
    horas:[6,7,8,9,10,11,12,13,14,15,16,17,18],
    minutos:['00','30'],
    hora:null,
    minuto:null,
    menu:false,
    envio:{descripcion:null},
    dateplaceholder: new Date().toISOString(),
    fecha:null,
    isLoading:false,
    enviado:false,
    error:false,
    respuesta:null,
  }),
  components: {
    hsm,
    AlertasVue
  },
  mounted(){
//this.getColaRabbit()
    this.continuar1()
    this.continuar2()
  },
  methods:{
    async getColaRabbit(){
     this.cola_actual  =   0 
    },
    continuar1(){
      if(_.isEmpty(this.excel)){
        return true
      } else{
        return this.excel?true:false
      }
    },
    continuar2(){
      if(this.tipoEnvio){
        return false
      }else{
        if(this.fecha&&this.hora&&this.minuto&&this.envio.descripcion){
          return false
        }else{
          return true
        }
      }
    },
    archivoDeExcel(evento){
      this.excel=evento
    },
    nombreDeHsm(evento){
      this.model=evento 
    },
    enviarArchivo(envioId){
      this.isLoading=true
      let formData = new FormData()
      formData.append("files", this.excel)
      formData.append("capturista_id",this.sesion.id)
      formData.append("hsm_id", this.model.hsmid)
      formData.append("origen", this.model.origen.id)
      formData.append("diashsm", this.diasHsm)
      formData.append("tipo_envio", this.tipoEnvio?1:2)
      formData.append("repetidos", this.repetidos?1:0)
      formData.append("prueba", this.pruebas?1:0) 
      formData.append("notificacion_telefono", this.model.telefononotificacion) 

      if(envioId>0)
        formData.append("envioId", envioId); 

      Servicio.subirArchivo(formData).then((resp)=>{
        this.respuesta=resp
        this.nivel=1
        if(this.respuesta.noenviados[0]){
          this.error=true
        }else{
          this.error=false
          this.enviado=true
        }

        
      }).finally(()=>{
        this.isLoading=false
      })
    },
    generarEnvio(){
      this.isLoading=true
      this.envio.hsm_id=this.model.hsmid
      this.envio.capturista_id=this.sesion.id
      this.envio.fecha_envio=this.fecha
      this.envio.hora_envio=this.hora+':'+this.minuto
      this.envio.origen=this.model.origen.id
      Servicio.generarEnvio(this.envio).then((resp)=>{
        this.enviarArchivo(resp.result.id)
      }).finally(()=>{
        this.isLoading=false
      })
    }
  }
}
</script>